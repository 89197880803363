import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './auth/helpers/auth.guards';
import { TestDynamicFormComponent } from './FormCraft/test/test-dynamic-form.component';

const routes: Routes = [
    { path: '', redirectTo: '/tong-quan/ca-nhan', pathMatch: 'full' },
    {
        path: 'tong-quan',
        loadChildren: () => import('./main/dashboard/dashboard.module').then((m) => m.DashboardModule),
    },
    { path: 'he-thong', loadChildren: () => import('./main/manager/manager.module').then((m) => m.ManagerModule) },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule { }
