import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { first } from "rxjs/operators";

import { CurrentAccount } from 'src/app/auth/models';

import { HttpService, NotifyService } from "src/app/core"
import { AuthenticationService } from 'src/app/auth/services';

@Component({
  selector: 'app-navbar',
  styleUrls: ['./navbar.component.css'],
  templateUrl: './navbar.component.html'
})
export class NavbarComponent implements OnInit {
  public currentAccount!: CurrentAccount;
  private a_microservices: string = 'nguoi-dung'
  image: string;
  notifications: any;
  /**
   * Constructor
   *
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   * @param {CoreConfigService} _coreConfigService
   * @param {CoreSidebarService} _coreSidebarService
   * @param {CoreMediaService} _coreMediaService
   * @param {MediaObserver} _mediaObserver
   * @param {TranslateService} _translateService
   */
  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    private _notify: NotifyService,
    private _authenticationService: AuthenticationService,
    private _http: HttpService,
  ) {
    this._authenticationService.currentAccount.subscribe(x => (this.currentAccount = (x as CurrentAccount)));
  }

  ngOnInit(): void {

    this.image = '/assets/images/logo_icon_cchc.png';
    if (this.currentAccount != null) {
      if (this.currentAccount.image != null && this.currentAccount.image != "") {
        this._http.post(this.a_microservices, 'get_image', this.currentAccount.image, (response: any) => {

          if (!response.success) {
            this._notify.error(response.message);
            return;
          }
          this.image = "data:image/png;base64," + response.data;

        });

      }
      this.GetNotificationByAccountId();
    }

  }
  GetNotificationByAccountId() {
    this._http.post(this.a_microservices, 'GetNotificationByAccountId', {}, (response: any) => {
      if (!response.success) {
        this._notify.error(response.message);
        return;
      }
      this.notifications = response.notifications;
    })
  }
  Bo_sung(account_id: number) {
    // this._router.navigate(['bosung', account_id], { relativeTo: this.route });
    this._router.navigate(['/he-thong/bosung', account_id]);
  }
  onLogout(): void {
    // redirect to home if already logged in
    if (!this._authenticationService.currentAccountValue) {
      this._router.navigate(['/']);
      return;
    }
    //
    this._authenticationService.logout().pipe(first()).subscribe(response => {
      if (!response.success) {
        this._notify.error(response.message);
        return;
      }
      //
      location.reload();
      return;
    }, error => {
      //this.alertService.error(error);
      //this.loading = false;
    });
  }
}
