import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';
var CryptoJS = require("crypto-js");

import { AppConfig } from "src/app/app-config";
import { AuthenticationService } from 'src/app/auth/services';

@Injectable({ providedIn: "root" })
export class HttpService {
  private base_url: string = `${AppConfig.settings.api_gateway}/v1`;
  /**
   *
   * @param {HttpClient} _http
   * @param {AuthenticationService} _authenticationService
   */
  constructor(
    private _http: HttpClient,
    private _spinner: NgxSpinnerService,
    private _authenticationService: AuthenticationService
  ) {
  }

  post(microservices: string, key: string, data: any, success_callback: any) {
    this._spinner.show();
    //
    const account = this._authenticationService.currentAccountValue;
    const is_logged_in = account && account.access_token;
    if (!is_logged_in) {
      // ? Can also logout and reload if needed
      this._authenticationService.logout();
      location.reload();
      return;
    }
    //
    var $time_stamp = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
    //
    var $data = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(data));
    var $request = {
      value: {},
      key: key,
      account_id: account.account_id,
      unit_id: account.unit_id,
      access_token: account.access_token,
      time_stamp: $time_stamp,
      nonce: 0
    };
    if (data !== null) $request.value = $data;
    //create auth access value     
    var $auth_access = CryptoJS.SHA256(JSON.stringify($request)).toString();
    // add authorization header with jwt token if available
    const $headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=UTF-8',
      Accept: 'application/json;odata=verbose',
      'Auth-Access': $auth_access,
      'Time-Access': $time_stamp,
      'Authorization': `Bearer ${account.access_token}`
    });
    //
    this._http.post(`${this.base_url}/${microservices}/post`, $request, { headers: $headers }).toPromise().then((response: any) => {
      success_callback(response);
      //
      this._spinner.hide();
    }, (error: any) => {
      //
      this._spinner.hide();
    });
  }

  postUri(uri: string, data: any, success_callback: any) {
    this._spinner.show();
    //
    const account = this._authenticationService.currentAccountValue;
    const is_logged_in = account && account.access_token;
    if (!is_logged_in) {
      // ? Can also logout and reload if needed
      this._authenticationService.logout();
      location.reload();
      return;
    }
    //
    var $time_stamp = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
    //
    var $data = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(data));
    var $request = {
      value: {},
      key: "",
      account_id: account.account_id,
      unit_id: account.unit_id,
      access_token: account.access_token,
      time_stamp: $time_stamp,
      nonce: 0
    };
    if (data !== null) $request.value = $data;
    //create auth access value
    var $auth_access = CryptoJS.SHA256(JSON.stringify($request)).toString();
    // add authorization header with jwt token if available
    const $headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=UTF-8',
      Accept: 'application/json;odata=verbose',
      'Auth-Access': $auth_access,
      'Time-Access': $time_stamp,
      'Authorization': `Bearer ${account.access_token}`
    });
    //
    this._http.post(uri, $request, { headers: $headers }).toPromise().then((response: any) => {
      success_callback(response);
      //
      this._spinner.hide();
    }, (error: any) => {
      //
      this._spinner.hide();
    });
  }
}
