import { Component, OnInit } from '@angular/core';

import { AppConfig } from 'src/app/app-config';

@Component({
  selector: 'horizontal-layout',
  templateUrl: './horizontal-layout.component.html'
})
export class HorizontalLayoutComponent implements OnInit {
  appConfig: any;

  constructor() { }

  ngOnInit(): void {
    this.appConfig = AppConfig.settings;
  }
}
