import { Injectable } from "@angular/core";

import { ToastrService } from 'ngx-toastr';
import { ConfirmBoxInitializer, DialogLayoutDisplay, DisappearanceAnimation, AppearanceAnimation } from '@costlydeveloper/ngx-awesome-popup';

@Injectable({ providedIn: "root" })
export class NotifyService {
  /**
   *
   * @param {ToastrService} _toastrService
   */
  constructor(
    private _toastrService: ToastrService
  ) { }

  error(message: string) {
    this._toastrService.error(message, "Thông báo!", {
      closeButton: true,
      enableHtml: true,
      progressBar: true
    });
  }

  success(message: string) {
    this._toastrService.success(message, "Thông báo!", {
      closeButton: true,
      enableHtml: true,
      progressBar: true
    });
  }

  info(message: string) {
    this._toastrService.info(message, "Thông báo!", {
      closeButton: true,
      enableHtml: true,
      progressBar: true
    });
  }

  warning(message: string) {
    this._toastrService.warning(message, "Thông báo!", {
      closeButton: true,
      enableHtml: true,
      progressBar: true
    });
  }

  alert(message: string) {
    this._toastrService.info(message, "Thông báo!", {
      positionClass: 'toast-top-center',
      tapToDismiss: false,
      closeButton: true,
      disableTimeOut: true
    });
  }

  confirm(message: string, confirm_callback: any) {
    const confirmBox = new ConfirmBoxInitializer();
    //
    confirmBox.setTitle('Thông báo!');
    confirmBox.setMessage(message);
    // Choose layout color type
    confirmBox.setConfig({
      layoutType: DialogLayoutDisplay.INFO, // SUCCESS | INFO | NONE | DANGER | WARNING
      animationIn: AppearanceAnimation.FADE_IN, // BOUNCE_IN | SWING | ZOOM_IN | ZOOM_IN_ROTATE | ELASTIC | JELLO | FADE_IN | SLIDE_IN_UP | SLIDE_IN_DOWN | SLIDE_IN_LEFT | SLIDE_IN_RIGHT | NONE
      animationOut: DisappearanceAnimation.ZOOM_OUT, // BOUNCE_OUT | ZOOM_OUT | ZOOM_OUT_WIND | ZOOM_OUT_ROTATE | FLIP_OUT | SLIDE_OUT_UP | SLIDE_OUT_DOWN | SLIDE_OUT_LEFT | SLIDE_OUT_RIGHT | NONE
      allowHtmlMessage: true,
      buttonPosition: 'center', // optional 
    });
    confirmBox.setButtonLabels('Đồng ý', 'Từ chối');
    // Simply open the popup and observe button click
    confirmBox.openConfirmBox$().subscribe(response => {
      confirm_callback(response);
    });
  }
}
