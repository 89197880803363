import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VerticalLayoutComponent } from './vertical-layout.component';

@NgModule({
  declarations: [
    VerticalLayoutComponent
  ],
  imports: [
    CommonModule
  ]
})
export class VerticalLayoutModule { }
