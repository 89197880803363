<!-- Navbar -->
<div class="navbar navbar-expand navbar-light px-0 px-lg-3">
  <div class="overflow-auto overflow-lg-visible scrollbar-hidden flex-1">
    <ul class="navbar-nav flex-row text-nowrap">
      <div *ngFor="let menuItem of form">
        <!-- Type 1 : Mega menu-->
        <li class="nav-item nav-item-dropdown-lg mega-menu-full" *ngIf="menuItem.subMenuType == 'mega'">
          <a *ngIf="menuItem.subMenuType" class="navbar-nav-link {{ menuItem.subMenu && 'dropdown-toggle' }}" data-toggle="dropdown"><i class="{{ menuItem.icon }} mr-2"></i> {{ menuItem.name }}</a>
          <div class="dropdown-menu dropdown-scrollable-lg wmin-lg-300 p-0">
            <div class="dropdown-content-body">
              <div class="row">
                <div class="col-md-3 mb-3 mb-md-0" *ngFor="let subItem of menuItem.subMenu">
                  <div class="font-weight-semibold border-bottom pb-2 mb-2">{{ subItem.name }}</div>
                  <a *ngFor="let opt of subItem.inSubMenu" routerLink="{{ opt.rlink }}" class="dropdown-item rounded"><i class="{{ opt.icon }}"></i> {{ opt.name }}</a>
                </div>
              </div>
            </div>
          </div>
        </li>
        <!-- /type 1 -->
        <!-- Type 2: Level Menu -->
        <li class="nav-item nav-item-dropdown-lg dropdown active" *ngIf="menuItem.subMenuType == 'level'">
          <a class="navbar-nav-link {{ menuItem.subMenu && 'dropdown-toggle' }}" data-toggle="dropdown"><i class="{{ menuItem.icon }} mr-2"></i> {{ menuItem.name }}</a>
          <div class="dropdown-menu">
            <div class="dropdown-header">{{ menuItem.subMenu.subMenuTitle }}</div>
            <a *ngFor="let subItem of menuItem.subMenu" routerLink="{{ subItem.rlink }}" class="dropdown-item rounded"><i *ngIf="subItem.icon" class="{{ subItem.icon }}"></i> {{ subItem.name }}</a>
          </div>
        </li>
        <!-- /type 2 -->
        <!-- Type 3: Menu item -->
        <li class="nav-item nav-item-dropdown-lg dropdown active" *ngIf="menuItem.rlink">
          <a routerLink="{{ menuItem.rlink }}" class="navbar-nav-link" data-toggle="dropdown"><i class="{{ menuItem.icon }} mr-2"></i> {{ menuItem.name }}</a>
        </li>
        <!-- /type 3 -->
      </div>
    </ul>
  </div>
</div>
<!-- / Navbar -->
