<!-- Main navbar -->
<div class="navbar navbar-expand-lg navbar-dark bg-indigo navbar-static shadow-none" style="height: 60px">
    <div class="d-flex flex-1 d-lg-none">
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-mobile">
            <i class="icon-paragraph-justify3"></i>
            <span class="badge badge-yellow border-warning m-1"></span>
        </button>
    </div>
    <div class="navbar-brand wmin-0 mr-lg-5" style="margin-top: 3px">
        <a href="/" class="d-inline-block">
            <img src="/assets/images/logo_icon_cchc.png" style="height: 35px" class="d-none d-sm-block" alt="" />
            <img src="/assets/images/logo_icon_cchc.png" style="height: 35px" class="d-sm-none" alt="" />
        </a>
    </div>
    <div class="collapse navbar-collapse order-2 order-lg-1" id="navbar-mobile">
        <ul class="navbar-nav" style="color: white; font-size: 20px">
            Nông thôn mới thông minh
        </ul>
    </div>
    <ul class="navbar-nav flex-row order-1 order-lg-2 flex-1 flex-lg-0 justify-content-end align-items-center">
        <!-- <li class="nav-item dropdown">
            <a href="#" class="navbar-nav-link" data-toggle="dropdown">
                <i class="icon-bell3"></i>
                <span class="badge badge-mark border-yellow ml-auto ml-lg-0"></span>
            </a>
            <div class="dropdown-menu dropdown-menu-right wmin-lg-350">
                <div class="dropdown-content-header">
                    <span class="font-weight-semibold">Thông báo mới nhất</span>
                    <a href="#" class="text-body"><i class="icon-search4 font-size-base"></i></a>
                </div>
                <div class="dropdown-content-body dropdown-scrollable">
                    <ul class="media-list">
                        <li class="media">
                            <div class="mr-3">
                                <a href="#" class="btn btn-success rounded-pill btn-icon"
                                    ><i class="icon-mention"></i
                                ></a>
                            </div>
                            <div class="media-body">
                                <a href="#">Taylor Swift</a> mentioned you in a post "Angular JS. Tips and tricks"
                                <div class="font-size-sm text-muted mt-1">4 minutes ago</div>
                            </div>
                        </li>
                        <li class="media">
                            <div class="mr-3">
                                <a href="#" class="btn btn-pink rounded-pill btn-icon"
                                    ><i class="icon-paperplane"></i
                                ></a>
                            </div>
                            <div class="media-body">
                                Special offers have been sent to subscribed users by <a href="#">Donna Gordon</a>
                                <div class="font-size-sm text-muted mt-1">36 minutes ago</div>
                            </div>
                        </li>
                        <li class="media">
                            <div class="mr-3">
                                <a href="#" class="btn btn-primary rounded-pill btn-icon"><i class="icon-plus3"></i></a>
                            </div>
                            <div class="media-body">
                                <a href="#">Chris Arney</a> created a new
                                <span class="font-weight-semibold">Design</span> branch in
                                <span class="font-weight-semibold">Limitless</span> repository
                                <div class="font-size-sm text-muted mt-1">2 hours ago</div>
                            </div>
                        </li>
                        <li class="media">
                            <div class="mr-3">
                                <a href="#" class="btn btn-purple rounded-pill btn-icon"><i class="icon-truck"></i></a>
                            </div>
                            <div class="media-body">
                                Shipping cost to the Netherlands has been reduced, database updated
                                <div class="font-size-sm text-muted mt-1">Feb 8, 11:30</div>
                            </div>
                        </li>
                        <li class="media">
                            <div class="mr-3">
                                <a href="#" class="btn btn-warning rounded-pill btn-icon"
                                    ><i class="icon-comment"></i
                                ></a>
                            </div>
                            <div class="media-body">
                                New review received on <a href="#">Server side integration</a> services
                                <div class="font-size-sm text-muted mt-1">Feb 2, 10:20</div>
                            </div>
                        </li>
                        <li class="media">
                            <div class="mr-3">
                                <a href="#" class="btn btn-teal rounded-pill btn-icon"
                                    ><i class="icon-spinner11"></i
                                ></a>
                            </div>
                            <div class="media-body">
                                <strong>January, 2018</strong> - 1320 new users, 3284 orders, $49,390 revenue
                                <div class="font-size-sm text-muted mt-1">Feb 1, 05:46</div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="dropdown-content-footer bg-light">
                    <a href="#" class="text-body mr-auto">Tất cả thông báo</a>
                    <div>
                        <a href="#" class="text-body" data-popup="tooltip" title="Clear list"
                            ><i class="icon-checkmark3"></i
                        ></a>
                        <a href="#" class="text-body ml-2" data-popup="tooltip" title="Settings"
                            ><i class="icon-gear"></i
                        ></a>
                    </div>
                </div>
            </div>
        </li> -->
        <li class="nav-item dropdown">
            <a href="#" class="navbar-nav-link" data-toggle="dropdown">
                <i class="icon-bell3"></i>
                <!-- Hiển thị số lượng thông báo bên trong vòng tròn lớn hơn -->
                <span *ngIf="notifications?.length > 0" class="badge notification-badge">{{
                    notifications.length
                }}</span>
            </a>
            <div class="dropdown-menu dropdown-menu-right wmin-lg-350">
                <div class="dropdown-content-header">
                    <span class="font-weight-semibold">Thông báo mới nhất</span>
                    <a href="#" class="text-body"><i class="icon-search4 font-size-base"></i></a>
                </div>
                <div class="dropdown-content-body dropdown-scrollable">
                    <ul class="media-list">
                        <li class="media" *ngFor="let notification of notifications">
                            <div class="media-body">
                                <a (click)="Bo_sung(notification.account_id)">{{ notification.contents }}</a>
                                <div class="font-size-sm text-muted mt-1">
                                    {{ notification.created_date | date : 'dd/MM/yyyy' }}
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <!-- <div class="dropdown-content-footer bg-light">
                    <a href="#" class="text-body mr-auto">Tất cả thông báo</a>
                    <div>
                        <a href="#" class="text-body" data-popup="tooltip" title="Clear list"
                            ><i class="icon-checkmark3"></i
                        ></a>
                        <a href="#" class="text-body ml-2" data-popup="tooltip" title="Settings"
                            ><i class="icon-gear"></i
                        ></a>
                    </div>
                </div> -->
            </div>
        </li>
        <li class="nav-item nav-item-dropdown-lg dropdown dropdown-user h-100" *ngIf="this.currentAccount != null">
            <a
                href="#"
                class="navbar-nav-link navbar-nav-link-toggler d-inline-flex align-items-center h-100 dropdown-toggle"
                data-toggle="dropdown"
            >
                <img [src]="image" class="rounded-pill" height="34" alt="" />
                <span class="d-none d-lg-inline-block ml-2">{{ this.currentAccount.full_name }}</span>
            </a>
            <div class="dropdown-menu dropdown-menu-right">
                <a href="#" class="dropdown-item" routerLink="/he-thong/thong-tin-tai-khoan"
                    ><i class="icon-user-plus"></i> Thông tin tài khoản</a
                >
                <div class="dropdown-divider"></div>
                <a href="#" class="dropdown-item" routerLink="/he-thong/doi-mat-khau"
                    ><i class="icon-cog5"></i> Đổi mật khẩu</a
                >
                <a class="dropdown-item" (click)="onLogout()"><i class="icon-switch2"></i> Đăng xuất</a>
            </div>
        </li>
    </ul>
</div>
<!-- /main navbar -->
