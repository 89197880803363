	<!-- Footer -->
<div class="navbar navbar-expand-lg navbar-light">
  <div class="text-center d-lg-none w-100"><button type="button" class="navbar-toggler dropdown-toggle" data-toggle="collapse" data-target="#navbar-footer"><i class="icon-unfold mr-2"></i>Thông tin</button></div>

  <div class="navbar-collapse collapse" id="navbar-footer">
    <!-- <span class="navbar-text">&copy; 2023. Phát triển bởi <a href="http://niq.vn" target="_blank">NIQ Việt Nam</a></span>
 -->
    <ul class="navbar-nav ml-lg-auto">
      <!-- <li class="nav-item"><a href="https://kopyov.ticksy.com/" class="navbar-nav-link" target="_blank"><i class="icon-lifebuoy mr-2"></i> Hỗ trợ</a></li>
      <li class="nav-item"><a href="https://demo.interface.club/limitless/docs/" class="navbar-nav-link" target="_blank"><i class="icon-file-text2 mr-2"></i> Hướng dẫn sử dụng</a></li> -->
    </ul>
  </div>
</div>
<!-- /footer -->
