<!-- Inner content -->
<div class="content-inner">
  <!-- Content area -->
  <div class="content d-flex justify-content-center align-items-center">
    <!-- Login form -->
    <form [formGroup]="login_form" (ngSubmit)="onSubmit()" class="login-form">
      <div class="card mb-0">
        <div class="card-body">
          <div class="text-center mb-3">
            <i class="icon-reading icon-2x text-secondary border-secondary border-3 rounded-pill p-3 mb-3 mt-1"></i>
            <h5 class="mb-0">ĐĂNG NHẬP HỆ THỐNG</h5>
            <span class="d-block text-muted">Thông tin tài khoản</span>
          </div>
          <div class="form-group form-group-feedback form-group-feedback-left">
            <input type="text" id="user_name" name="user_name" formControlName="user_name" placeholder="Tài khoản" class="form-control" />
            <div class="form-control-feedback"><i class="icon-user text-muted"></i></div>
          </div>
          <div class="form-group form-group-feedback form-group-feedback-left">
            <input type="password" id="password" name="password" formControlName="password" class="form-control" placeholder="Mật khẩu" />
            <div class="form-control-feedback"><i class="icon-lock2 text-muted"></i></div>
          </div>
          <div class="form-group d-flex align-items-center">
            <label class="custom-control custom-checkbox">
              <input type="checkbox" name="remember" formControlName="remember" class="custom-control-input" checked>
              <span class="custom-control-label">Nhớ tài khoản</span>
            </label>
          </div>
          <div class="form-group"><button type="submit" class="btn btn-primary btn-block">Đăng nhập <i class="icon-circle-right2 ml-2"></i></button></div>
          <!-- <div class="form-group text-center text-muted content-divider"><span class="px-2">Hoặc</span></div> -->

          <!-- <div class="form-group"><button type="button" class="btn btn-primary btn-block" (click)="onSSOClick()" >Đăng nhập qua Hệ thống xác thực tập trung <i class="icon-circle-right2 ml-2"></i></button></div> -->
          <div class="form-group text-center text-muted content-divider"><span class="px-2"></span></div>
          <span class="form-text text-center text-muted"><a href="#">Điều khoản &amp; Điều kiện</a> và <a href="#">Chính sách bảo mật</a></span>
        </div>
      </div>
    </form>
    <!-- /login form -->
  </div>
  <!-- /content area -->
</div>
<!-- /inner content -->
