import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { NavbarModule } from 'src/app/layout/components/navbar/navbar.module';
import { ContentModule } from 'src/app/layout/components/content/content.module';
import { MenuModule } from 'src/app/layout/components/menu/menu.module';
import { FooterModule } from 'src/app/layout/components/footer/footer.module';

import { HorizontalLayoutComponent } from './horizontal-layout.component';

@NgModule({
  declarations: [HorizontalLayoutComponent],
  imports: [RouterModule, CommonModule, NavbarModule, ContentModule, MenuModule, FooterModule],
  exports: [HorizontalLayoutComponent]
})
export class HorizontalLayoutModule { }
