import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { HorizontalLayoutModule } from './horizontal/horizontal-layout.module';
import { VerticalLayoutModule } from './vertical/vertical-layout.module';

@NgModule({
    declarations: [],
    imports: [
        FlexLayoutModule.withConfig({ disableDefaultBps: true }),
        HorizontalLayoutModule,
        VerticalLayoutModule
    ],
    exports: [HorizontalLayoutModule],
})
export class LayoutModule {}
