import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';

import { CurrentAccount } from 'src/app/auth/models';
import { AuthenticationService } from 'src/app/auth/services';
import { HttpService, NotifyService } from 'src/app/core';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
})
export class MenuComponent implements OnInit {
    public account!: CurrentAccount;
    //
    form: any;
    active = '';
    functionals: any;

    constructor(
        private _router: Router,
        private _formBuidler: FormBuilder,
        private _http: HttpService,
        private _notify: NotifyService,
        private _authenticationService: AuthenticationService,
    ) {
        this._authenticationService.currentAccount.subscribe((x) => (this.account = x as CurrentAccount));
    }

    async ngOnInit(): Promise<void> {
        if (this.account) {
            var functionals = JSON.parse(sessionStorage.getItem('current-functional') || '{}');
            if (
                (sessionStorage.getItem('current-functional') == undefined ||
                    sessionStorage.getItem('current-functional') == null) &&
                this._authenticationService.currentAccountValue
            ) {
                await this._authenticationService
                    .auto_login()
                    .toPromise()
                    .then(
                        (response) => {
                            if (!response.success) {
                                alert(response.message);
                                this._router.navigate(['/dang-nhap'], { queryParams: { returnUrl: '/' } });
                            }
                            //
                            functionals = response.functionals;
                        },
                        (error) => {
                            //this.alertService.error(error);
                            //this.loading = false;
                        },
                    );
            }
            this.form = this.data2menuForm(functionals);
        }
    }

    data2menuForm(data: any) {
        let menuForm: {
            id: number;
            name: any;
            icon: any;
            level: number;
            subMenuType: string;
            subMenu: any;
        }[] = [];

        // Sorting  by sort_order
        data = data.sort((a: any, b: any) => {
            return a.sort_order - b.sort_order;
        });

        // add level 1 of menu
        data.forEach((element: any, index: number) => {
            if (element.parent_id === 0) {
                let el = {
                    id: element.id,
                    name: element.name,
                    icon: element.icon,
                    level: 1,
                    subMenuType: element.display_type == 1 ? 'level' : element.display_type == 2 ? 'mega' : 'null',
                    rlink: element.action_link.charAt(0) === '/' ? element.action_link : null,
                    subMenu: [],
                }; //menu element

                // menuForm = [...menuForm, el]; //spread way
                menuForm.push(el);
            }
        });

        // add level 2
        menuForm.forEach((element: any, index: number) => {
            data.forEach((e: any, i: number) => {
                if (e.parent_id === element.id) {
                    if (element.subMenuType === 'level') {
                        element.subMenu.push({
                            name: e.name,
                            icon: e.icon,
                            rlink: e.action_link,
                        });
                    }

                    if (element.subMenuType === 'mega') {
                        element.subMenu.push({
                            id: e.id,
                            name: e.name.toUpperCase(),
                            inSubMenu: [],
                        });
                    }
                }
            });
        });

        // add level 3
        menuForm.forEach((element: any, index: number) => {
            element.subMenu.forEach((el: any) => {
                data.forEach((e: any, i: number) => {
                    if (e.parent_id === el.id) {
                        el.inSubMenu.push({
                            name: e.name,
                            icon: e.icon,
                            rlink: e.action_link,
                        });
                    }
                });
            });
        });

        return menuForm;
    }
}
