import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';
var CryptoJS = require("crypto-js");

import { AppConfig } from "src/app/app-config";
import { CurrentAccount } from 'src/app/auth/models';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //public
  public currentAccount: Observable<CurrentAccount | null>;
  //private
  private currentAccountSubject: BehaviorSubject<CurrentAccount | null>;
  private deviceInfo?: DeviceInfo;
  /**
   *
   * @param {HttpClient} _http
   */
  constructor(
    private _http: HttpClient,

    private _spinner: NgxSpinnerService,
    private _deviceService: DeviceDetectorService
  ) {
    var current_account = localStorage.getItem('current-account') || sessionStorage.getItem('current-account');
    this.currentAccountSubject = new BehaviorSubject<CurrentAccount | null>(current_account ? JSON.parse(current_account) : null);
    this.currentAccount = this.currentAccountSubject.asObservable();
  }

  // getter: currentAccountValue
  public get currentAccountValue(): CurrentAccount | null {
    return this.currentAccountSubject?.value;
  }

  /**
   * Account login
   *
   * @returns functionals and actions
   */
  auto_login() {
    const account = this.currentAccountValue;
    //
    var $time_stamp = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
    //
    var $request = {
      value: account?.access_token,
      key: "",
      account_id: 0,
      unit_id: 0,
      access_token: "",
      time_stamp: $time_stamp,
      nonce: 0
    };
    //create auth access value
    var $auth_access = CryptoJS.SHA256(JSON.stringify($request)).toString();
    // add authorization header with jwt token if available
    const $headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=UTF-8',
      Accept: 'application/json;odata=verbose',
      'Auth-Access': $auth_access,
      'Time-Access': $time_stamp,
      'Authorization': `Bearer ${account?.access_token}`
    });
    //
    return this._http.post<any>(`${AppConfig.settings.api_gateway}/v1/xac-thuc/td-dang-nhap`, $request, { headers: $headers }).pipe(map(response => {
      // login successful if there's a jwt token in the response
      if (response && response.success) {
        // store account details and jwt token in local storage to keep user logged in between page refreshes
        sessionStorage.setItem('current-functional', JSON.stringify(response.functionals));
        sessionStorage.setItem('current-action', JSON.stringify(response.actions));
      }
      //
      return response;
    }));
  }

  /**
   * Account login
   *
   * @param user_name
   * @param password
   * @returns account
   */
  login(user_name: string, password: string, remember: boolean) {
    this.deviceInfo = this._deviceService.getDeviceInfo();
    const user_agent = this.deviceInfo.userAgent;
    const os = this.deviceInfo.os;
    const browser_name = this.deviceInfo.browser;
    const device_name = this.deviceInfo.device;
    const os_version = this.deviceInfo.os_version;
    const browser_version = this.deviceInfo.browser_version;
    const device_type = this.deviceInfo.deviceType;
    //
    var data = {
      user_name,
      password,
      user_agent,
      os,
      browser_name,
      device_name,
      os_version,
      browser_version,
      device_type
    };
    //
    var $time_stamp = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
    //
    var $data = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(JSON.stringify(data)));
    //
    var $request = {
      value: {},
      key: '',
      account_id: 0,
      unit_id: 0,
      access_token: '',
      time_stamp: $time_stamp,
      nonce: 0
    };
    $request.value = $data;
    //create auth access value
    var $auth_access = CryptoJS.SHA256(JSON.stringify($request)).toString();
    // add authorization header with jwt token if available
    const $headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=UTF-8',
      Accept: 'application/json;odata=verbose',
      'Auth-Access': $auth_access,
      'Time-Access': $time_stamp
    });
    //
    return this._http.post<any>(`${AppConfig.settings.api_gateway}/v1/xac-thuc/dang-nhap`, $request, { headers: $headers }).pipe(map(response => {
      // login successful if there's a jwt token in the response
      if (response && response.success && response.access_token) {
        response.account.access_token = response.access_token;
        // store account details and jwt token in local storage to keep user logged in between page refreshes
        if (remember) {
          localStorage.setItem("access_token", response.access_token);
          localStorage.setItem('current-account', JSON.stringify(response.account));
        } else {
          sessionStorage.setItem("access_token", response.access_token);
          sessionStorage.setItem('current-account', JSON.stringify(response.account));
        }
        sessionStorage.setItem('current-functional', JSON.stringify(response.functionals));
        sessionStorage.setItem('current-action', JSON.stringify(response.actions));
        // notify
        this.currentAccountSubject.next(response.account);
      }
      //
      return response;
    }));
  }
  hasAction(code: string) {
    var actions = JSON.parse(sessionStorage.getItem('current-action') || '{}');
    var result = actions.filter(obj => {
      return obj === code
    })
    if (result != null && result != undefined && result.length > 0) {
      return true;
    }
    return false;
  }
  /**
   * Account logout
   *
   */
  logout() {
    this._spinner.show();
    //
    const account = this.currentAccountValue;
    //
    var $time_stamp = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
    //
    var $request = {
      value: account?.access_token,
      key: "",
      account_id: 0,
      unit_id: 0,
      access_token: "",
      time_stamp: $time_stamp,
      nonce: 0
    };
    //create auth access value
    var $auth_access = CryptoJS.SHA256(JSON.stringify($request)).toString();
    // add authorization header with jwt token if available
    const $headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=UTF-8',
      Accept: 'application/json;odata=verbose',
      'Auth-Access': $auth_access,
      'Time-Access': $time_stamp,
      'Authorization': `Bearer ${account?.access_token}`
    });
    //
    return this._http.post<any>(`${AppConfig.settings.api_gateway}/v1/xac-thuc/dang-xuat`, $request, { headers: $headers }).pipe(map(response => {
      if (!response && !response.success) {
        return response;
      }
      // remove account from local storage to log user out
      localStorage.removeItem('access_token');
      localStorage.removeItem('current-account');
      sessionStorage.removeItem('access_token');
      sessionStorage.removeItem('current-account');
      sessionStorage.removeItem('current-functional');
      sessionStorage.removeItem('current-action');
      // notify
      this.currentAccountSubject.next(null);
      //
      return response;
    }));
  }
  /**
   * get_sso_link_login
   *
   * @returns functionals and actions
   */
  get_sso_link_login() {
    const account = this.currentAccountValue;
    //
    var $time_stamp = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
    //
    var $request = {
      value: account?.access_token,
      key: "",
      account_id: 0,
      unit_id: 0,
      access_token: "",
      time_stamp: $time_stamp,
      nonce: 0
    };
    //create auth access value
    var $auth_access = CryptoJS.SHA256(JSON.stringify($request)).toString();
    // add authorization header with jwt token if available
    const $headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=UTF-8',
      'Accept': 'application/json;odata=verbose',
      'Auth-Access': $auth_access,
      'Time-Access': $time_stamp,
      'Authorization': `Bearer `
    });
    //
    return this._http.post<any>(`${AppConfig.settings.api_gateway}/v1/xac-thuc/link-sso`, $request, { headers: $headers }).pipe(map(response => {

      return response;
    }));
  }
}
