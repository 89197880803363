import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from 'src/app/auth/services';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  /**
   *
   * @param {AuthenticationService} _authenticationService
   */
  constructor(private _authenticationService: AuthenticationService) {}

  /**
   * Add auth header with jwt if user is logged in and request is to api url
   * @param request
   * @param next
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //const currentAccount = this._authenticationService.currentAccountValue;
    //const isLoggedIn = currentAccount && currentAccount.token;
    //// const isApiUrl = request.url.startsWith(environment.apiUrl);
    //if (isLoggedIn) {// && isApiUrl
    //  request = request.clone({
    //    setHeaders: {
    //      Authorization: `Bearer ${currentAccount.token}`
    //    }
    //  });
    //}
    //
    return next.handle(request);
  }
}
