import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

//import { AppConfig } from 'src/app/app-config';

import { LoginComponent } from './login.component';

const routes: Routes = [
  {
    path: 'dang-nhap',
    component: LoginComponent,
    // canActivate: [AuthGuard]
    data: { animation: 'dang-nhap' },
    title: 'Đăng nhập hệ thống'
  }
];

@NgModule({
  declarations: [LoginComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes)
  ],
  exports: [LoginComponent]
})
export class LoginModule { }
