import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
//import { first } from 'lodash';
import { first } from "rxjs/operators";
import { NotifyService } from "src/app/core"

import { AppConfig } from "src/app/app-config";

import { AuthenticationService } from 'src/app/auth/services';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
  login_form: FormGroup = new FormGroup({
    user_name: new FormControl(''),
    password: new FormControl(''),
    remember: new FormControl(false)
  });
  acc: any;
  constructor(
    private _formBuidler: FormBuilder,
    private _route: ActivatedRoute,
    private _notify: NotifyService,

    private _router: Router,
    private _authenticationService: AuthenticationService
  ) {
    // redirect to home if already logged in
    if (this._authenticationService.currentAccountValue) {
      this._router.navigate(['/']);
      return;
    }
    // Configure the layout
    AppConfig.settings.menu.hidden = true;
    AppConfig.settings.navbar.hidden = true;
    AppConfig.settings.footer.hidden = true;
  }

  ngOnInit(): void {
    this.login_form = this._formBuidler.group({
      user_name: ['', Validators.required],
      password: ['', Validators.required],
      remember: [false]
    });
  }

  onSubmit(): void {
    // stop here if form is invalid
    if (this.login_form.invalid) {
      return;
    }
    //
    const f = this.login_form.value;
    this._authenticationService.login(f.user_name, f.password, f.remember).pipe(first()).subscribe(response => {
      if (!response.success) {
        this._notify.error(response.message);
        return;
      }
      this.acc = response.account;
      // Configure the layout
      // get return url from route parameters or default to '/'
      if (this.acc.is_first_login === true) {
        AppConfig.settings.menu.hidden = false;
        AppConfig.settings.navbar.hidden = false;
        AppConfig.settings.footer.hidden = false;
        const next = this._route.snapshot.queryParams["next"] || "/";
        if (!next || next == '/') {
          this._router.navigate(['/']);
          return;
        }
        //
        this._router.navigate([next]);
      }
      else {

        this._router.navigate(['/he-thong/doi-mat-khau']);

      }
    }, error => {
      //this.alertService.error(error);
      //this.loading = false;
    });
  }

  onSSOClick(): void {
    window.location.href = `${AppConfig.settings.api_gateway}/v1/home/link-sso`;

    // this._authenticationService.get_sso_link_login().pipe(first()).subscribe(response => {
    //  var uri_path =response.uri_path;
    //  console.log(uri_path);
    //   this._router.navigate([uri_path]);
    // }, error => {
    //   //this.alertService.error(error);
    //   //this.loading = false;
    // });
  }
}
