<body class="navbar-md-sm-top">
  <div class="fixed-top">
    <ng-container *ngTemplateOutlet="navbar"></ng-container>
    <ng-container *ngTemplateOutlet="menu"></ng-container>
  </div>
  <!-- Page content -->
  <div class="page-content pt-2">
    <!-- Main content -->
    <div class="content-wrapper">
      <content></content>
    </div>
    <!-- /main content -->
  </div>
  <!-- /page content -->
  <ng-container *ngTemplateOutlet="footer"></ng-container>
</body>

<ng-template #navbar><app-navbar *ngIf="!appConfig.navbar.hidden"></app-navbar></ng-template>
<ng-template #menu><app-menu *ngIf="!appConfig.menu.hidden"></app-menu></ng-template>
<ng-template #footer><app-footer *ngIf="!appConfig.footer.hidden"></app-footer></ng-template>
